import React from "react"
import SocialLinks from "../../constants/socialLinks"
import { Box, Typography } from "@mui/material"

const HeroBanner = () => {
  return (
    <Box
      component="div"
      sx={{
        px: {
          xs: "2rem",
          sm: "3rem",
        },
        minHeight: "80vh",
        color: "var(--clr-light)",
        display: "flex",
        alignItems: "center",
      }}
      className="bg-color:gradient"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "center", sm: "flex-start" },
          textAlign: { xs: "center", sm: "left" },
        }}
      >
        <Typography
          variant="h1"
          component="h2"
          gutterBottom
          sx={{
            fontSize: {
              xs: "3rem",
              md: "4rem",
            },
            fontStyle: "italic",
            borderBottom: "2px solid var(--clr-yellow)",
            display: "inline-block",
            marginBottom: "var(--s2)",
          }}
        >
          Xin chào!
        </Typography>
        <p style={{ fontSize: "1.25em" }}>Let's learn Vietnamese together.</p>
        <SocialLinks></SocialLinks>
      </Box>
    </Box>
  )
}

export default HeroBanner
