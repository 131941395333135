import React from "react"
import { Grid } from "@mui/material"
import options from "../../constants/options"
import ItemCard from "./ItemCard"

const Items = ({ styleClass }) => {
  return (
    <div style={{ margin: "var(--s3)" }} className={styleClass}>
      <div style={{ textAlign: "center", marginBottom: "var(--s1)" }}>
        <h3 className="underline">Explore</h3>
      </div>
      <Grid container spacing={2}>
        {options.map(option => {
          return (
            <Grid item key={option.id} md={4} sm={6} xs={12}>
              <ItemCard item={option} />
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

export default Items
