import React from "react"
import { FaBookOpen, FaSketch, FaLaptop } from "react-icons/fa"

const services = [
  {
    id: 1,
    icon: <FaBookOpen className="service-icon" />,
    title: "Grammar",
    text: `Your guide to Vietnamese grammar.`,
    url: "/category/grammar",
  },
  {
    id: 2,
    icon: <FaSketch className="service-icon" />,
    title: "Vocabulary",
    text: `Learn Vietnamese vocabulary by topic.`,
    url: "/category/vocabulary",
  },
  // {
  //   id: 3,
  //   icon: <FaLaptop className="service-icon" />,
  //   title: "Membership",
  //   text: `Become a member and enjoy the perks.`,
  //   url: "/membership",
  // },
  {
    id: 3,
    icon: <FaLaptop className="service-icon" />,
    title: "Guides",
    text: `Check all our Vietnamese guides.`,
    url: "/guides",
  },
]

export default services
