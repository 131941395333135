import React from "react"
import { Link } from "gatsby"
import { Box, Card, CardContent } from "@mui/material"

const ItemCard = ({ item }) => {
  const { icon, title, text, url } = item
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <CardContent>
        <Link to={url}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              gap: "1rem",
              p: 1,
            }}
          >
            {icon}

            <h4>{title}</h4>

            <p>{text}</p>
          </Box>
        </Link>
      </CardContent>
    </Card>
  )
}

export default ItemCard
