import React from "react"
import HeroBanner from "../components/homepage/HeroBanner"
import Layout from "../components/layout/Layout"
import Seo from "../components/SEO"
import Items from "../components/homepage/Items"

const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title="Learn Vietnamese Online Free"
        desc="Learn Vietnamese with our free online grammar, vocabulary, listening, and reading resources. Practice your Vietnamese today. "
      />
      <HeroBanner />
      <Items />
    </Layout>
  )
}

export default IndexPage
